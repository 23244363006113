import router from '@/router'

const state = {
  token: localStorage.getItem('token') || null,
}

const actions = {
  setToken({ commit }, payload) {
    commit('setToken', payload)
  },
  removeToken({ commit }) {
    commit('removeToken')
  },
}

const mutations = {
  setToken(state, payload) {
    state.token = payload
    localStorage.setItem('token', payload)
  },
  removeToken(state) {
    state.token = null
    localStorage.removeItem('token')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

router.beforeEach((to, from, next) => {
  if (
    to.name != 'auth' &&
    (!state.token || state.token == 'undefined' || state.token == null)
  ) {
    next({ name: 'auth' })
  }
  next()
})
