import store from '@/store'
import router from '@/router'

export const request = async (handler, data) => {
    let response = await fetch(process.env.VUE_APP_API_URL + '/' + handler, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.device.token,
        },
    })
    if (response.ok) { // if HTTP-status is 200-299
        return await response.json()
    } else {
        // store.dispatch('device/removeToken')
        return router.push({ name: 'auth' })
        // alert('HTTP-Error: ' + response.status)    
    }
}
