import Vue from 'vue'
import Vuex, { createStore } from 'vuex'
import device from './modules/device'
import employee from './modules/employee'
import timer from './modules/timer'
Vue.use(Vuex)


export default createStore({
  modules: {
    device,
    employee,
    timer,
  }
})
