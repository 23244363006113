<template>
  <div class="container">
    <!-- <button @click="codeScanned('0002424485')">Scan code</button> -->
    <div v-if="employee" class="header">
      <div class="header__employee">
        <img
          :src="employee.photo"
          v-if="employee.photo"
          class="profile_photo"
          alt=""
        />
        <h2>{{ employee.fullName }}</h2>
      </div>
      <div class="header__navigation">
        <router-link
          :to="{ name: 'employee' }"
          custom
          v-slot="{ navigate, isActive }"
        >
          <button
            @click="navigate"
            role="link"
            :class="{ active: isActive }"
            class="menu-button"
          >
            <img :src="Clock" alt="" />
          </button>
        </router-link>
        <router-link
          :to="{ name: 'notifications' }"
          custom
          v-slot="{ navigate, isActive }"
        >
          <button
            @click="navigate"
            role="link"
            :class="{ active: isActive }"
            class="menu-button"
          >
            <img :src="Notifications" alt="" />
          </button>
        </router-link>
        <router-link
          :to="{ name: 'attendance' }"
          custom
          v-slot="{ navigate, isActive }"
        >
          <button
            @click="navigate"
            role="link"
            :class="{ active: isActive }"
            class="menu-button"
          >
            <img :src="Attendance" alt="" />
          </button>
        </router-link>
        <router-link
          :to="{ name: 'manufacture' }"
          custom
          v-slot="{ navigate, isActive }"
        >
          <button
            @click="navigate"
            role="link"
            :class="{ active: isActive }"
            class="menu-button"
          >
            <img :src="Manufacture" alt="" />
          </button>
        </router-link>
        <router-link
          :to="{ name: 'menu' }"
          custom
          v-slot="{ navigate, isActive }"
        >
          <button
            @click="navigate"
            role="link"
            :class="{ active: isActive }"
            class="menu-button"
          >
            <img :src="Menu" alt="" />
          </button>
        </router-link>
        <button class="close menu-button" @click="logout">
          <img :src="Logout" alt="" />
        </button>
      </div>
    </div>
    <router-view class="app-container" v-slot="{ Component }">
      <view-container :loading="loading">
        <component :is="Component" />
      </view-container>
    </router-view>
    <div v-if="message" :class="['message', { green: success, red: !success }]">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { request } from '@/api/request'
import { mapState } from 'vuex'

import Menu from '@/assets/img/menu.svg'
import Manufacture from '@/assets/img/manufacture.svg'
import Attendance from '@/assets/img/attendance.svg'
import Notifications from '@/assets/img/notifications.svg'
import Clock from '@/assets/img/clock.svg'
import Logout from '@/assets/img/logout.svg'

export default {
  name: 'App',

  data: () => ({
    message: null,
    loading: 0,
    code: '',
  }),
  watch: {
    message: function (newVal, oldVal) {
      if (newVal != oldVal) {
        let timeout
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          this.message = null
        }, 5000)
      }
    },
    redirect: function (newVal, oldVal) {
      if (newVal != oldVal && newVal == true) {
        this.logout()
      }
    },
  },
  computed: {
    ...mapState({ employee: (state) => state.employee.employeeData }),
    ...mapState({ redirect: (state) => state.timer.redirect }),
  },
  methods: {
    async codeScanned(code) {
      this.loading = 1
      this.logout()
      await this.selectEmployee(code)
      this.employeeAutoCloseTimeout(20000)
      this.loading = 0
    },
    testConnection() {
      window.addEventListener('offline', () =>
        alert(
          'Pozor! Žiadny prístup na internet! Je potrebné pripojit zariadenie na internet k sfunkčeniu dochádzky.'
        )
      )
    },
    loadScanner() {
      document.addEventListener(
        'keypress',
        (event) => {
          if (event.key == 'Enter') {
            if (this.code.length >= 10) {
              this.codeScanned(this.code)
              this.code = ''
            }
          } else {
            this.code += event.key
          }

          if (!this.reading) {
            this.reading = true
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
              this.code = ''
              this.reading = false
            }, 200)
          }
        },
        true
      )
    },
    employeeAutoCloseTimeout(time = 30000) {
      this.$store.commit('timer/setTime', time)
    },
    logout() {
      this.$store.dispatch('employee/logout')
      this.$store.commit('timer/clearTime')

      this.checkRedirect('clock')
    },
    async selectEmployee(code) {
      const data = await request('getEmployee', {
        code: code,
      })

      if (data.success) {
        await this.$store.dispatch('employee/login', data.employee)
        await this.$router.push({ name: 'employee' })
      } else {
        this.message = 'Kód karty nie je registrovaný v systéme.'
      }
    },
    async loadAttendanceTypes() {
      const data = await request('getAttendanceTypes')
      this.$store.dispatch('employee/setAttendanceTypes', data)
    },
    checkRedirect(routeName) {
      if (this.$route.name != routeName) {
        this.$router.push({ name: routeName })
      }
    },
  },
  mounted() {
    this.loadScanner()

    this.testConnection()
  },
  async created() {
    await this.loadAttendanceTypes()
  },
  setup() {
    return {
      Menu,
      Notifications,
      Attendance,
      Manufacture,
      Clock,
      Logout,
    }
  },
}
</script>

<style lang="scss">
.view-container {
  overflow: auto;
  height: 100vh;
}
.app-container {
  height: calc(100% - 64px);
  padding: 16px;
}
.container {
  min-height: 100vh;
  height: 100%;
}
.profile_photo {
  width: 56px;
  height: 56px;
  border-radius: 4px;
  margin-left: 5px;
}
.header {
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #222;
  padding: 8px 8px 8px 16px;
  &__employee {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &__navigation {
    display: flex;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background-color: #a7a7a7;
      }
      &.close {
        margin-right: 0;
        margin-left: 10px;
        padding: 0;
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
  h2 {
    color: #fff;
    margin: 0;
    font-weight: 400;
  }
}
.menu-button {
  width: 56px;
  height: 56px;
}
</style>
