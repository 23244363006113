const state = {
  time: null,
  redirect: false,
}

const mutations = {
  setTime(state, payload) {
    mutations.clearTime(state)
    state.time = setTimeout(() => {
      state.redirect = true
    }, payload)
  },
  clearTime(state) {
    state.redirect = false
    clearTimeout(state.time)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
