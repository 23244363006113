import Vue, { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import '@/assets/app.scss'

import ViewContainer from '@/components/layout/ViewContainer.vue'
import Spinner from '@/components/Spinner.vue'

Vue.component('ViewContainer', ViewContainer)
Vue.component('Spinner', Spinner)

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_VERSION,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost:8080", "attendance.dajanarodriguez.app", /^\//],
      }),
    ],
		debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
    tracesSampleRate: 0.2,
		tracingOptions: {
			trackComponents: true,
		},
		// Vue specific
		logErrors: process.env.NODE_ENV === 'production' ? false : true,
		attachProps: true,
		attachStacktrace: true,
  });
}

createApp(App)
  .use(router)
  .use(store)
  .mount('#app')