import router from '../../router'

const state = {
  id: null,
  employeeData: null,
  attendanceTypes: [],
}

const actions = {
  login({ commit }, payload) {
    commit('login', payload)
  },
  logout({ commit }) {
    commit('logout')
  },
  setAttendanceTypes({ commit }, payload) {
    commit('setAttendanceTypes', payload)
  },
}

const mutations = {
  setAttendanceTypes(state, payload) {
    state.attendanceTypes = payload
  },
  login(state, payload) {
    state.id = payload.id
    state.employeeData = payload
  },
  logout(state) {
    state.id = state.employeeData = null
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

router.beforeEach((to, from, next) => {
  if (to.meta.requiredLogin && !state.id) {
    next({ name: 'clock' })
  }
  next()
})
