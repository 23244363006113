import { createRouter, createWebHistory } from 'vue-router'
import ViewMiddle from './ViewMiddle.vue'

const routes = [
  {
    path: '/',
    name: 'clock',
    component: () => import('./views/Clock.vue'),
    meta: {
      requiredLogin: false,
    },
  },
  {
    path: '/',
    component: ViewMiddle,
    children: [
      {
        path: '/auth',
        name: 'auth',
        component: () => import('./views/Auth.vue'),
        meta: {
          requiredLogin: false,
        },
      },
    ],
  },
  {
    path: '/employee',
    name: 'employee',
    component: () => import('./views/Employee.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('./views/MealMenu.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/attendance',
    name: 'attendance',
    component: () => import('./views/Attendance.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/manufacture',
    name: 'manufacture',
    component: () => import('./views/Manufacture.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('./views/Notifications.vue'),
    meta: {
      requiredLogin: true,
    },
  }
]

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
